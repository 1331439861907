import { Box } from '@mui/material'
import React from 'react'

interface Props {
  disabled?: boolean
}

const DataGridWrapper: React.FC<Props> = ({ disabled = false, children }) => {
  if (disabled) {
    return <>{children}</>
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', flexGrow: 1 }}>
      <div style={{ flexGrow: 1 }}>{children}</div>
    </Box>
  )
}

export default DataGridWrapper
