import React from 'react'
import GenericTablePage from '@templates/Generic/Table'
import { PageProps } from 'gatsby'
import { Button } from 'gatsby-material-ui-components'
import { ButtonGroup } from '@mui/material'
import { Delete } from '@mui/icons-material'
import useApi from '@hooks/useApi'
import { GridComponentProps } from '@mui/x-data-grid-pro'
import { deleteErrors, getErrors } from '@utils/api/errors'

const columns: GridComponentProps['columns'] = [
  {
    field: 'id',
    headerName: 'Error Id',
    width: 120,
    hide: true
  },
  {
    field: 'host_id',
    headerName: 'Host Id',
    width: 120,
    hide: true
  },
  {
    field: 'hostname',
    headerName: 'Target Address',
    width: 150,
    type: 'enhancedString'
  },
  {
    field: 'header.start_time',
    headerName: 'Time',
    type: 'dateTime',
    valueGetter: ({ row }) => row.header.start_time,
    width: 170
  },
  {
    field: 'error_msg',
    headerName: 'Message',
    width: 450,
    flex: 1
  },
  {
    field: 'ip_addr',
    headerName: 'IP',
    width: 150
  },

  {
    field: 'queue_name',
    headerName: 'Queue',
    width: 150
  }
]

const ScanningErrorsPage = ({}: PageProps) => {
  const api = useApi({ apiMethod: getErrors })
  const deleteApi = useApi({ apiMethod: deleteErrors, requestOnMount: false })

  const handleDelete = async () => {
    await deleteApi.makeRequest().then(() => {
      deleteApi.enqueueSnackbar('Delete in progress. This may take a moment.', {
        variant: 'success'
      })
      setTimeout(() => api.makeRequest(), 1000)
    })
  }

  return (
    <GenericTablePage
      breadcrumbs={[
        {
          title: 'Scanning',
          to: '/scan/'
        },
        {
          title: 'Scanning Error Log'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button endIcon={<Delete />} onClick={handleDelete}>
            Clear Error Log
          </Button>
        </ButtonGroup>
      }
      gridOptions={{
        checkboxSelection: false,
        columns,
        initialSort: [
          {
            field: 'header.start_time',
            sort: 'desc'
          }
        ]
      }}
      title="Scanning Error Log"
      api={api}
    />
  )
}

export default ScanningErrorsPage
