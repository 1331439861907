import http from '../http'
import { ApiResponse, ScrollingApiResponse } from './types'

export type Error = {
  data: string
  error_msg: string
  header: {
    date_last_seen: string | null
    end_time: string | null
    host_id: string | null
    manual_scan: boolean
    priority: number
    queue_name: string
    run_id: string
    start_time: string
    tags: string[]
    target: string
    target_ssh_port: number
    version: string
  }
  host_id: string | null
  hostname: string | null
  id: string | null
  ip_addr: string | null
  queue_name: string | null
  run_id: string | null
}

export type Errors = Error[]
export type ErrorsResponse = ScrollingApiResponse<Errors>

export const getErrors = async (): Promise<ErrorsResponse> =>
  await http.get(`/errors`)

export const deleteErrors = async (): Promise<ApiResponse<string>> =>
  await http.delete(`/errors`)
