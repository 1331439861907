import React, { FC } from 'react'
import useApi from '@hooks/useApi'
import { transformApiResponseToRows } from '@utils/tables'
import { DataGrid } from '@components'
import { IDataGridProps } from '@components/DataGrid'
import { TBulkActions, TPresets } from '@components/DataGrid/DataGridToolbar'
import GenericPage, { GenericPageProps } from './Page'
import { GridComponentProps } from '@mui/x-data-grid-pro'
import DataGridWrapper from '@components/DataGrid/DataGridWrapper'

export type GenericTablePageProps = GenericPageProps & {
  api: ReturnType<typeof useApi>
  //columns: GridColumns

  /**
   * Provide a prop in response that contains the item array
   */
  arrayKey?: string

  /**
   * Provide a prop in each array item to use as a unique key
   */
  keyProp?: string | 'id'
  gridOptions: {
    columns: GridComponentProps['columns']
    editCellHandler?: IDataGridProps['config']['editCellHandler']
    [key: string]: any
  }
  bulkActions?: TBulkActions
  presets?: TPresets
}

const GenericTablePage: FC<GenericTablePageProps> = ({
  api,
  arrayKey,
  keyProp = 'id',
  gridOptions: { columns, ...options },
  bulkActions = [],
  presets = [],
  maxWidth = false,
  ...pageProps
}) => {
  const { loading, error, response, makeRequest } = api

  const config: IDataGridProps['config'] = {
    signature: '',
    historySupport: true,
    elevation: 1,
    loading,
    error: !!error || null,
    columns,
    rows: transformApiResponseToRows({
      response,
      arrayKey
    }),
    getRowId: (row: any) => row[keyProp],
    bulkActions,
    presets,
    makeRequest,
    ...options
  }

  return (
    <GenericPage maxWidth={maxWidth} {...pageProps}>
      <DataGridWrapper>
        <DataGrid config={config} />
      </DataGridWrapper>
    </GenericPage>
  )
}

export default GenericTablePage
